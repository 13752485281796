import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => registration.unregister());
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
