import { Container, Row, Col, Tabs, Tab, Button, Badge, Form, InputGroup } from 'react-bootstrap';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import { MaritraceUser, getMaritraceUsers } from '../ComponentData/UserData.js';
import { handleSASSRecordDetails } from '../ModuleFunctions/Notifications.js';
import { session_user_uuid, session_parent_uuid } from '../session.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomDatePicker from '../../Components/CustomDatePicker';
import { handleNotificationRender, basicNotification } from './Notifications.js';
import CustomCheckbox from '../../Components/CustomCheckbox';
import { sendDataToParent } from './Iframe_Handler.js';
import * as SP from '../ComponentData/SSAS_Page.js';
import CustomTable from '../../Components/CustomTable';
import { createRoot } from 'react-dom/client';
import * as G from '../Other/General.js';
import { v4 as uuidv4 } from 'uuid';

export function getAlertTableData(data, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const active_alerts_columns = [
        columnHelper.accessor('vessel_info.vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('vessel_info.current_status', {
            header: <span className="center_header">{headers[1] ?? 'STATUS'}</span>,
            enableColumnFilter: false,
            cell: info => Array.from(SP.alertStatusElement.values()).find(([statusString]) => statusString === info.getValue())?.[1] ?? SP.SSASFailedStatus, //getAlertStatusElement(info.getValue()?.toUpperCase()),
        }),
        columnHelper.accessor('locate', {
            header: <span className="right_header">{headers[2] ?? 'LOCATE'}</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5"
                    onClick={() => flyToMobile([info.row.original.vessel_info.lon, info.row.original.vessel_info.lat])}
                    title="Centre map on vessel">
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return active_alerts_columns;
}

export function getScheduleTableData(data, is_dash, callbacks, userData, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");
    let schedule_columns = [];

    if(!is_dash){
       schedule_columns.push(columnHelper.accessor('expand', {
         header: 'EXPAND',
         enableColumnFilter: false,
         cell: info => {
           const isExpanded = info.getValue(); // Get the current value for this cell
           return (
             <p className="align-center">
             <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
               onClick={() => callbacks.toggleExpandTestSchedule(info.row.original.schedule_uuid)} >
               <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
             </button></p>
           );
         }
       }));
    }
    schedule_columns.push(columnHelper.accessor('schedule_date', {
       header: 'DATE (UTC)',
       filterFn: 'includesString',
       cell: info => info.getValue().replace('T', ' ').substring(0, 16),
    }));

    schedule_columns.push(columnHelper.accessor('vessel_info.vessel_name', {
       header: 'VESSEL',
       filterFn: 'includesString',
       cell: info => {
            let result = info?.getValue() ? info.getValue() : "UNKNOWN";
            return result;
       }
    }));

    if(!is_dash){
    　 schedule_columns.push(columnHelper.accessor('schedule_by', {
         header: 'Scheduled by',
         filterFn: 'includesString',
         cell: info => info.getValue().user_name,
       }));
    }
    schedule_columns.push(columnHelper.accessor('current_status', {
        header: <span className="center_header">OUTCOME</span>,
        filterFn: 'equals',
        cell: info => {
        return Array.from(SP.scheduledTestStatuses.values()).find(([statusString]) => statusString === info.getValue())?.[1] ?? SP.SSASFailedStatus;
       }
    }));
    schedule_columns.push(columnHelper.accessor('locate', {
       header: <span className="right_header">LOCATE</span>,
       enableColumnFilter: false,
       cell: info => <p class="align-right"><button className="btn fas pt1-5" onClick={() => flyToMobile([info.row.original.vessel_info.lon, info.row.original.vessel_info.lat])} title="Centre map on vessel" value="mobile_find">
           <FontAwesomeIcon icon="crosshairs"/>
       </button></p>,
    }),
    columnHelper.accessor('', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.current_status === SP.scheduledTestStatuses.get(1)?.[0] ? (
          <Button className="delete_link" variant="light" onClick={() => changeScheduledTestDetails(info.row.original, userData, "DELETE")}>DELETE</Button>
        ) : null;},
      })
    );

    return schedule_columns;
}

export function getContactsTableData(data, callbacks, columns_names, master_table = false, currentUser = SP.defaultSsasContact){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    let is_master = !currentUser.master;

    const contacts_columns = [
      columnHelper.accessor('expand', {
        header: 'Expand',
        enableColumnFilter: false,
        cell: info => {
          const isExpanded = info.getValue(); // Get the current value for this cell
          return (<p className="align-center">
            <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
              onClick={() => callbacks.toggleExpandContact(info.row.original.contact_uuid)} >
              <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
            </button></p>
          );
      }}),
      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let value = info.getValue();


            if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "internal_bool", master_table)} disabled={is_master} /></p>);
        }}),
      columnHelper.accessor('master', {
        header: 'Master',
        enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;

           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "master"} initialState={value}
           three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "master", master_table)} disabled={is_master} /></p>);
      }}),
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          editable: true,
          cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
              return <input defaultValue={String(info.getValue() ?? "")}
                type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_name")} disabled={disable_box} />
          }
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_company")} disabled={disable_box} />
        }
      }),
      columnHelper.accessor('user_role', {
        header: 'Position',
        cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_role")} disabled={disable_box} />
        }
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
            return <input defaultValue={String(info.getValue() ?? "")}
              type="text" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_email")} disabled={disable_box} />
        }
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
            return <input defaultValue={String(info.getValue() ?? "")}
              type="number" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_phone")} disabled={disable_box} />
        }
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => {
              let disable_box = is_master;
              if(currentUser.contact_uuid === info.row.original.contact_uuid) disable_box = false;
            return <input defaultValue={String(info.getValue() ?? "")}
              type="number" onBlur={(e) => updateContactFromTable(info.row.original, String(info.getValue() ?? ""), e.target.value, "user_sms")} disabled={disable_box} />
        }
      }),
      columnHelper.accessor('active_email', {
        header: 'Active Email',
           enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;

           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_email"} initialState={value}
           three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "active_email", master_table)} disabled={is_master} /></p>);
      }}),

      columnHelper.accessor('active_screen', {
        header: 'Active Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = is_master;
            let value = info.getValue();
            if(info.row.original.internal_bool !== true){
                disable_box = true;
                value = 0;
            }
            else if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_screen"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "active_screen", master_table)} disabled={disable_box} /></p>);
        }}),

      columnHelper.accessor('test_email', {
        header: 'Test Email',
        enableColumnFilter: false,
        cell: info => {
             const row_id = info.row.original.row_id;
             let value = info.getValue();

             if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_email"} initialState={value}
             three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "test_emsil", master_table)} disabled={is_master} /></p>);
        }}),
      columnHelper.accessor('test_screen', {
        header: 'Test Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
             let disable_box = is_master;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_screen"} initialState={value}
             three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "test_screen", master_table)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('delete_able', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.master === false ? (
          <Button className="delete_link" variant="light" style={{ pointerEvents: "all", cursor: is_master ? "not-allowed" : "pointer"}}
          onClick={() => updateContactFromTable(info.row.original, "", "delete", "DELETE")} disabled={is_master}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getVesselTabContactsTableData(data, callbacks, columns_names, ssas_vessel_uuid, userContact){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const contacts_columns = [
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          editable: true,
          cell: info => info.getValue()
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_role', {
        header: 'Position',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('active_email', {
        header: 'Active Email',
           enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let value = info.getValue();
           let disable_box = false;
           if(value) value = 1;
           else value = 0;

           if(info.row.original.active_email) { value = 1; disable_box = true; }
           else if(info.row.original.relation && info.row.original.relation[0].active_email) { value = 1; disable_box = false;}

           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_email"} initialState={value}
           three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "active_email", ssas_vessel_uuid)} disabled={disable_box} /></p>);
      }}),

      columnHelper.accessor('active_screen', {
        header: 'Active Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();
            if(value) value = 1;
            else value = 0;

            //console.log("info.row.original: ", info.row.original); console.log("FFS!");
            if(info.row.original.internal_bool){
                if(info.row.original.active_screen) { value = 1; disable_box = true;}
                else if(info.row.original.relation && info.row.original.relation[0].active_screen) { value = 1; disable_box = false;}
            }
            else{
                disable_box = true;
                value = 0;
            }
            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_screen"} initialState={value}
            three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "active_screen", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),

      columnHelper.accessor('test_email', {
        header: 'Test Email',
        enableColumnFilter: false,
        cell: info => {
             const row_id = info.row.original.row_id;
             let value = info.getValue();
             let disable_box = false;
             if(value) value = 1;
             else value = 0;


             if(info.row.original.test_email) { value = 1; disable_box = true; }
             else if(info.row.original.relation && info.row.original.relation[0].test_email) { value = 1; disable_box = false;}

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_email"} initialState={value}
             three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "test_email", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('test_screen', {
        header: 'Test Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(value) value = 1;
             else value = 0;

             if(info.row.original.internal_bool){
                 if(info.row.original.test_screen) { value = 1; disable_box = true; }
                 else if(info.row.original.relation && info.row.original.relation[0].test_screen) { value = 1; disable_box = false;}
             }
             else{
                 disable_box = true;
                 value = 0;
             }
             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_screen"} initialState={value}
             three_states={false} onChange={(e) => contactAssociationCheckboxChange(info.row.original, e, "test_screen", ssas_vessel_uuid)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('delete_able', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {
        let display = true;
        if(info.row.original.active_email || info.row.original.active_screen || info.row.original.test_email || info.row.original.test_screen){
            display = false;
        }
        return display && userContact != null && (userContact.master === true || userContact.contact_uuid === info.row.original.contact_uuid) ? (
          <Button className="delete_link" variant="light" onClick={() => callbacks.deleteContactLink(info.row.original)}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getContactsCascadeTableData(data, callbacks, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const contacts_columns = [
      columnHelper.accessor('priority_level', {
        header: 'Priority Level',
        enableColumnFilter: false,
        cell: info => {
            return <input defaultValue={String(info.getValue() ?? "")}
                type="number" onBlur={(e) => changePriority(info.row.original, String(info.getValue() ?? ""), e.target.value, "priority_level")} />
        }
      }),
      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();

            if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={(e) => contactCheckboxChange(info.row.original, e, "internal_bool", false)} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          cell: info => info.getValue()
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('', {
        header: 'Log Contact',
        enableColumnFilter: false,
        cell: info => <button className="log_link"  onClick={() => logContactAttempt(info.row.original.user_name , info.row.original.contact_uuid)}>LOG</button>
        }),
      columnHelper.accessor('', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.row.original.master === false ? (
          <Button className="delete_link" variant="light" onClick={() => updateContactFromTable(info.row.original, "", "delete", "DELETE")}>DELETE</Button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getEventsLogsTableData(data, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const events_columns = [
        columnHelper.accessor('log_date', {
            header: 'LOG DATE',
            cell: info => info.getValue().replace('T', ' ').substring(0, 16),
        }),
        columnHelper.accessor('log_type', {
            header: 'LOG TYPE',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('log_mode', {
            header: <span className="center_header">MODE</span>,
            filterFn: 'equals',
            cell: info => Array.from(SP.logStatusElement.values()).find(([statusString]) => statusString === info.getValue())?.[1] ?? SP.SSASFailedStatus,
        }),
        columnHelper.accessor('contact_details.user_email', {
            header: 'EMAIL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_name', {
            header: 'NAME',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_company', {
            header: 'COMPANY',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('log_details', {
            header: 'OUTCOME',
            cell: info => <span title={info.getValue()}>{info.getValue()}</span>,
        }),
        columnHelper.accessor('ssas_vessel_details.vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('ssas_vessel_details.locate', {
            header: <span className="right_header">LOCATE</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                    onClick={() => flyToMobile([info.row.original.alert_details.lon, info.row.original.alert_details.lat])} >
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return events_columns;
};

export function getVesselsTableData(data, expand_contacts, expand_uuid, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const vessels_columns = [
        columnHelper.accessor('vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('current_status', {
            header: <span className="center_header">STATUS</span>,
            filterFn: 'equals',
            cell: info => Array.from(SP.alertStatusElement.values()).find(([statusString]) => statusString === info.getValue())?.[1] ?? SP.SSASFailedStatus,
        }),
    ];
    vessels_columns.push(
        columnHelper.accessor('next_test_date', {
         header: <span className="center_header">NEXT TEST (UTC)</span>,
         cell: info => {
               const value = info.getValue();
               let temp = "";
               if(value != null ){
                    temp = value ? value.replace('T', ' ').substring(0, 16) : "";
               }
               else{
                    temp = "UNSET";
               }
               return (<span className="center_text">{temp}</span>);
             },
        }),
        columnHelper.accessor('locate', {
         header: <span className="right_header">LOCATE</span>,
         enableColumnFilter: false,
         cell: info => (
             <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                 onClick={() => flyToMobile([info.row.original.lon, info.row.original.lat])} >
                 <FontAwesomeIcon icon={['fas', 'crosshairs']} />
             </button></p>
         ),
        })
    );

    return vessels_columns;
};

export function getTestVesselInfo(testList, vesselList){
    const vesselMap = new Map(vesselList.map(v => [v.ssas_vessel_uuid, v]));

    const updatedTestList = testList.map(item => {
        item.vessel_info = vesselMap.get(item.ssas_vessel_uuid);
        return item;
    });
    return updatedTestList;
};

export async function combineContactAffiliation(contactList, affiliationList) {

    contactList.forEach(item => {
        let affiliation = affiliationList.find(x => x.contact_uuid === item.contact_uuid);

        if (affiliation) {
            item.priority_level = affiliation.priority_level;
            item.relation = [affiliation];
        }
    });

    return contactList; // Optional, if you want to return the modified list
}

export const flyToMobile = (position: number[]) => {
  sendDataToParent({ item:'fly_to_location', value: position})
};

export function getRelevantSSAS_Status(code, type) {
    if (type === "schedule") {
        switch (code) {
            case 0:
                return "NOT APPLICABLE";
            case 1:
                return "PENDING";
            case 2:
                return "SUCCESSFUL";
            case 3:
                return "UNSUCCESSFUL";
            default:
                return "UNKNOWN";
        }
    }else if (type === "event") {
         switch (code) {
            case 0:
                 return "NOT APPLICABLE";
             case 1:
                 return "LIVE";
             case 2:
                 return "TEST";
             default:
                 return "UNKNOWN";
         }
     }else if (type === "vessels") {
           switch (code) {
              case 0:
                   return "NOT ENABLED";
               case 1:
                   return "SSAS ENABLED";
               case 1:
                   return "SSAS FIRING IN TEST MODE";
               case 2:
                   return "SSAS FIRING";
               default:
                   return "UNKNOWN";
           }
       }

    return "UNKNOWN";
}

export function expandScheduledTestContent(testData, userData, eventsColumns, breadcrumbs, testEvents, hasTestUnderway){
    return (
    <>
    <tr>

        <td></td>
        <td colSpan={5}>
          <Container className="test_schedule_expand">
            <Row>
                <Col className="column1">
                    <p className="text">Started (UTC):</p>
                </Col>
                <Col className="column2">
                    <Form.Group className="mb-3" controlId="testSchedule.startedUTC">
                        <Form.Control type="text" placeholder="Started (UTC)" value={testData.started_date ? testData.started_date.replace('T', ' ').substring(0, 16) : ""} readOnly/>
                    </Form.Group>
                </Col>
                <Col className="column3">
                    {testData.current_status == SP.scheduledTestStatuses.get(1)?.[0] && hasTestUnderway ? (
                        <Button className="test_status_btn" onClick={() => basicNotification("Vessel already has a test underway", "Failed")} variant="primary">START TEST</Button>
                    ) : testData.current_status == SP.scheduledTestStatuses.get(1)?.[0] && !hasTestUnderway ? (
                        <Button className="test_status_btn" onClick={() => changeScheduledTestDetails(testData, userData, 'START')} variant="primary">START TEST</Button>
                    ) : testData.current_status == SP.scheduledTestStatuses.get(2)?.[0] ? (
                        <Button className="test_status_btn" onClick={() => changeScheduledTestDetails(testData, userData, 'END')} variant="primary">END TEST</Button>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col className="column1">
                    <p className="text">Started by:</p>
                </Col>
                <Col className="column2">
                    <Form.Group className="mb-3" controlId="testSchedule.startedBy">
                        <Form.Control type="text" placeholder="Started by" value={testData.started_by ? testData.started_by.user_name : ""} readOnly/>
                    </Form.Group>
                </Col>
                <Col className="column3">
                </Col>
            </Row>
            <Row>
                <Col className="column1">
                   <p className="text">Completed:</p>
                </Col>
                <Col className="column2">
                    <Form.Group className="mb-3" controlId="testSchedule.completed">
                        <Form.Control type="text" placeholder="Completed" value={testData.completed_date ? testData.completed_date.replace('T', ' ').substring(0, 16) : ""} readOnly/>
                    </Form.Group>
                </Col>
                <Col className="column3">
                </Col>
            </Row>
            <Row>
                <Col className="column1">
                    <p className="text">Completed by:</p>
                </Col>
                <Col className="column2">
                    <Form.Group className="mb-3" controlId="testSchedule.completedBy">
                        <Form.Control type="text" placeholder="Completed by" value={testData.completed_by ? testData.completed_by.user_name : ""} readOnly/>
                    </Form.Group>
                </Col>
                <Col className="column3">
                </Col>
            </Row>
            <Row>
                <Col className="column1">
                    <p className="text">Notes:</p>
                </Col>
                <Col className="column2">
                    <Form.Group className="mb-3" controlId="testSchedule.notes">
                        <Form.Control type="text" placeholder="Notes" readOnly/>
                    </Form.Group>
                </Col>
                <Col className="column3">
                    <Button variant="light">Print PDF</Button>
                </Col>
            </Row>
          </Container>
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ScheduledTestExpandEventLogsTable"} columns={eventsColumns}
          data={testEvents ? testEvents : null} rows_displayed={testEvents && testEvents.length > 0 ? testEvents.length : 0}
          dropdown_placeholder={"All"} expand_element={null}/>
        </td>
      </tr>
    </>);
}

export function validateVesselEmailAddress(){
    let email_input = document.getElementById('vessel_email_for_ssas');
    if(email_input && email_input.value != "") return [true, email_input.value];
    else{
        let error_notice = document.getElementById('vessel_email_error_notice');
        error_notice.style.display = 'block';
        return [false, ""];
    }
}

export function getVesselDetailsButton(activeState, handleSSASActiveButton, SSASVesselData, userContact, affiliation?: SSASAffiliation | null){
    const statusKey = Array.from(SP.alertStatusElement.keys()).find((key) =>
        SP.alertStatusElement.get(key)?.[0] === activeState
    );
    if(statusKey === 3){
        const log_type = affiliation?.mute_live_alarm ?  SP.ssasLogTypes.get(20) : SP.ssasLogTypes.get(17);
        return (
            <Button className="SSAS_activate_button" variant="secondary" data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Cancel SSAS Request</Button>
        );
    }
    else if(statusKey === 2 || activeState === "SSAS ENABLED"){
        const log_type = SP.ssasLogTypes.get(14);
        return (
            <Button className="SSAS_activate_button" variant="light" data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Request SSAS Deactivation</Button>
        );
    }
    else if(statusKey === 1){
        const log_type = affiliation?.mute_live_alarm ?  SP.ssasLogTypes.get(7) : SP.ssasLogTypes.get(6);
        const log_type2 = SP.ssasLogTypes.get(8);
        if(affiliation != null && affiliation.mute_live_alarm == true){
            return (<>
                <Button className="SSAS_activate_button" variant="dark" style={{ marginBottom: '10px' }} data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Un-mute Alarm</Button>
                <Button className="SSAS_activate_button" variant="secondary" data-log-type={log_type2[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Cancel SSAS Alert</Button>
            </>);
        }
        else{
            return (<>
                <Button className="SSAS_activate_button" variant="info" style={{ marginBottom: '10px' }} data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Mute Alarm</Button>
                <Button className="SSAS_activate_button" variant="secondary" data-log-type={log_type2[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Cancel SSAS Alert</Button>
            </>);
        }
    }
    else if(statusKey === 0){
        const log_type = SP.ssasLogTypes.get(16);

        if(affiliation != null && affiliation.mute_test_alarm == true){
            return (<Button className="SSAS_activate_button" variant="dark" data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Un-mute Alarm</Button>);
        }
        else{
            return (<Button className="SSAS_activate_button" variant="info" data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Mute Alarm</Button>);
        }
    }
    else if(statusKey === 4){
        return null;
    }else{
        const log_type = SP.ssasLogTypes.get(0);
        return (
            <Button className="SSAS_activate_button" variant="light" data-log-type={log_type[1]} onClick={(e) => handleSSASActiveButton(e.currentTarget.dataset.logType, SSASVesselData, userContact, affiliation)}>Request SSAS Activation</Button>
        );
    }
}

export function logContactAttempt(user_name, user_id){
    const content = (
        <div>
            <Form.Check inline label="Telephone call" name="phone" type="radio" id="log_contact_phone" />
            <Form.Check inline label="Email" name="email" type="radio" id="log_contact_email" />
            <Form.Check inline label="SMS" name="sms" type="radio" id="log_contact_sms" />
            <Form.Check inline label="Voice call" name="voice_call" type="radio" id="log_contact_voice_call" />
            <Form.Check inline label="Other" name="other" type="radio" id="log_contact_other" />

            <Form.Control as="textarea" placeholder="Enter notes here"/>
        </div>
    );

    const buttons = (
        <div>
            <Button variant="success">Save</Button>
            <Button variant="secondary">cancel</Button>
        </div>
    );
    handleNotificationRender("log_contact_notif", "NOTIFICATION|LOGCONTACT", ["Add Contact log for Sheri Mcguire"], [content], [buttons], [true, "Cancel"], ["log_contact_cont"]);
}

async function addSelectedContact(user, callback){
    const result = await SP.insertNewSSASContact(null, user);
    if(result != null) callback(result);

}

export async function addContactNotif(existingContacts: SSASContact[] | null = null, callback){
    let userList = await getMaritraceUsers("", "", true);
    if(userList){
        const filteredUserList = userList.filter(item => {
            return !existingContacts.some(i => i.user_uuid === item.user_account_uuid);
        });
        if(filteredUserList){
            const content = (
                <div>
                    <Form.Select id="userSSASContactSelect" aria-label="Select a user">
                      <option></option>
                      {filteredUserList.map(user => (
                        <option value={user.user_account_uuid} >{user.first_name} {user.last_name}</option>
                      ))}
                    </Form.Select>
                </div>
            );

            const buttons = (
                    <Button onClick={() => handleSSASContactOk(filteredUserList, callback)} variant="secondary">Ok</Button>
            );
            handleNotificationRender("add_contact_notif", "NOTIFICATION|ADDCONTACT", ["Select user to be added"], [content], [buttons], [true, "Cancel"], ["add_contact_cont"]);
        }
    }
}
function handleSSASContactOk(filteredUserList, callback) {
    const userSelect = document.getElementById("userSSASContactSelect");

    if (userSelect) {
        const selectedUserUUID = userSelect.value;
        const selectedUser = filteredUserList.find(user => user.user_account_uuid === selectedUserUUID);

        if (selectedUser) {
            addSelectedContact(selectedUser, callback);
        } else {
            console.error("No user selected or user not found!");
        }
    } else {
        console.error("User select element not found!");
    }
}

export async function contactCheckboxChange(row, event, variable, is_master){
    let parameters: eventLogParameters = SP.defaultLogParameters;
    parameters.log_type_number = 12;
    parameters.log_mode_number = 0;

    row[variable] = row[variable] === true? false : true;
    if(is_master){
        row.archive_contact_uuid = uuidv4();
        const upsertContact = {"contact_list" : [row]}

        const result = await SP.upsertSSASContact(upsertContact);

        if(result.is_successful){
            const creatingUser = await SP.getContactData([], [], true);

            parameters.contact = row;
            parameters.completed_uuid = creatingUser.contact_uuid;
            await SP.createPostSSASEventLogParam([parameters]);
        }
    }
}

export async function contactAssociationCheckboxChange(row, event, variable, ssas_vessel_uuid){
    row[variable] = row[variable] === true? false : true;
    let link_uuid = uuidv4();
    if(row.relation && row.relation.length > 0){
        link_uuid = row.relation[0].link_ssas_vessels_contacts_uuid;
    }
    const newAffiliation = {
        "SSASAffiliation_list": [{
            "link_ssas_vessels_contacts_uuid" : link_uuid,
            "contact_uuid": row.contact_uuid,
            "ssas_vessel_uuid": ssas_vessel_uuid.ssas_vessel_uuid,
            "active_email": row.active_email,
            "test_email": row.test_email,
            "active_screen": row.active_screen,
            "test_screen": row.test_screen,
            "deleted": row.deleted,
            "deleted_by": row.deleted_by
        }]
    }
    const result = await SP.upsertSSASAffiliation(newAffiliation);
    if(result) {
        let logNotes = "Update contact " + row.user_name + " " + variable.replace("_", " ") +
        " from " + (row[variable] === true ? false : true) + " to " + row[variable];
        await SP.logAffiliationEvents(row, ssas_vessel_uuid, logNotes);
    }
}

export async function updateContactFromTable(row, old_value, new_value, column_header){
    let notes = "";
    if (old_value != new_value){
        if(column_header.toUpperCase().includes("DELETE")){
            row.deleted = G.setUTCDate();
            row.deleted_by = { user_uuid: session_user_uuid, user_name: null }
            notes = "Deleted contact: "+ row.user_name;
        }
        else{
            row[column_header] = new_value;

            if(row.contact_uuid === G.getEmptyUUID()) {
                if(column_header !== "user_name"){
                    basicNotification("Please provide a user name first", "Failed");
                    return;
                }
                row.contact_uuid = uuidv4();
                notes = "Create new external contact "+row[column_header];
            }
            notes = "Edited contact "+ row.user_name +" "+column_header+": "+row[column_header];
        }
        if(row.archive_contact_uuid === null) row.archive_contact_uuid = uuidv4();
        const result = SP.insertNewSSASContact(row, null, notes);

        if(column_header.toUpperCase().includes("DELETE") && result){
            const res = await SP.getAffiliationData([],[row.contact_uuid],[]);
            res.forEach(item => {
                item.deleted = G.setUTCDate();
                item.deleted_by = { user_uuid: session_user_uuid, user_name: null }
            });
            const newAffiliation = { "SSASAffiliation_list": res };
            console.log("newAffiliation: ", newAffiliation);
            console.log("newAffiliation.SSASAffiliation_list: ", newAffiliation.SSASAffiliation_list);
            const result2 = await SP.upsertSSASAffiliation(newAffiliation);
            if(result2.is_successful){
                await SP.logAffiliationEvents(row, null, "DELETE");
                return newAffiliation;
            }
        }
    }
}

export async function changePriority(row, old_value, new_value, column_header){
    let link_uuid = uuidv4();
    if(row.relation && row.relation.length > 0){
        link_uuid = row.relation[0].link_ssas_vessels_contacts_uuid;
    }
    if(link_uuid && G.isNumberOnly(new_value)){
        const newAffiliation = {
            "SSASAffiliation_list": [{
                "link_ssas_vessels_contacts_uuid" : link_uuid,
                "contact_uuid": row.contact_uuid,
                "ssas_vessel_uuid": row.relation[0].ssas_vessel_uuid,
                "active_email": row.active_email,
                "test_email": row.test_email,
                "active_screen": row.active_screen,
                "test_screen": row.test_screen,
                "priority_level": new_value,
                "deleted": row.deleted,
                "deleted_by": row.deleted_by
            }]
        }

        const result = await SP.upsertSSASAffiliation(newAffiliation);
        if(result) {
            let logNotes = "Update contact "+ row.user_name +" priority from "+ old_value +" to "+ new_value;
            let resVessel = await SP.getSSASVesselData([row.relation[0].ssas_vessel_uuid], [], "", );
            await SP.logAffiliationEvents(row, resVessel[0], logNotes);
        }
    }
}

export function updateVesselEmail(ssasVessel, new_email = ""){
    if(new_email === "") new_email = document.getElementById("vessel_email_for_ssas");
    console.log("vessel_email_for_ssas: ", new_email);
    if(new_email != "" && ssasVessel.vessel_email != new_email){
        ssasVessel.vessel_email = new_email;
        const parameters = {
                "vessel_list" : [ssasVessel]}
        const result = SP.upsertSSASVessel(parameters);
    }
}

export function dashboardSSASScheduleTest(event, containerId, callback){
    var parent = document.getElementById(containerId);

        let popup_overlay = document.createElement('div');
        popup_overlay.className = 'overlay';
        popup_overlay.id = 'ssas_dashboard_date_popup';
        popup_overlay.onclick = function() {
            popup_overlay.remove();
        };

        let popup_content = document.createElement('div');
        popup_content.className = 'centered-content';
        popup_content.onclick = function(e) {
             e.stopPropagation();
         };
        let customDatePickerContainer = document.createElement('div');

        const root = createRoot(
            customDatePickerContainer
        );
        const today = G.setUTCDate();
        root.render(<CustomDatePicker
            breadcrumbs="SSASPage|Dashboard|DatePicker"
            startDate={null}
            endDate={null}
            minimumDate={null}
            maximumDate={null}
            range={false}
            include_time={false}
            call_back_func={(returnDate) => callback(event, returnDate)}
        />);

        popup_content.appendChild(customDatePickerContainer);
        popup_overlay.appendChild(popup_content);
        parent.appendChild(popup_overlay);
    return G.setUTCDate();
}

export async function changeScheduledTestDetails(scheduledTest, userData, changeCommand){
    let log_change = false;
    let log_notes = "";
    const parameters: SP.eventLogParameters = { alert: null, vessel: scheduledTest.vessel_info, scheduledTests: scheduledTest,
        contact: userData, log_type_number: 11,  log_mode_number: 2, result_notes: "",
        completed_uuid: userData.contact_uuid }

    if(changeCommand == "START"){
        scheduledTest.started_date = G.setUTCDate();
        scheduledTest.started_by.user_uuid = userData.contact_uuid;
        scheduledTest.current_status = await SP.scheduledTestStatuses.get(2)?.[0] ?? SP.SSASFailedStatus;
        const res = await SP.updateScheduledTestDetails(scheduledTest);

        if(res.is_successful){
            log_change = true;
            parameters.log_type_number = 9;
            parameters.result_notes = "Scheduled test started at "+ new Date().toISOString() + " (UTC) by contact: " + userData.user_name;
            parameters.vessel.current_Status = await SP.alertStatusElement.get(4)?.[0];

            const parametersVesselLog = parameters;
            scheduledTest.vessel_info.current_Status = parameters.vessel.current_Status
            const updateVessel = {"vessel_list" : [scheduledTest.vessel_info]};
            const res = await SP.upsertSSASVessel(updateVessel);
            parametersVesselLog.log_type_number = 19;
            parametersVesselLog.result_notes = "'"+scheduledTest.vessel_info.vessel_name+"' vessel Status Change '"+
                scheduledTest.vessel_info.current_Status+ "', Test mode activated at "+ new Date().toISOString() +
                " (UTC), test initiated by contact: " + userData.user_name;
            console.log("parametersVesselLog: ", parametersVesselLog);
            if(res.is_successful) SP.createPostSSASEventLogParam([parametersVesselLog]);
        }
    }
    if(changeCommand == "DELETE") {
        const result = await SP.getContactData([],[], true);
        scheduledTest.deleted = G.setUTCDate();
        if(result != null && result.length > 0) {
            scheduledTest.deleted_by = {user_uuid: result[0].contact_uuid, user_name: null};
            parameters.contact = result[0];
        }
        const res = await SP.updateScheduledTestDetails(scheduledTest);

        if(res.is_successful){
            log_change = true;
            parameters.log_type_number = 9;
            parameters.result_notes = "Scheduled test DELETED at "+ new Date().toISOString() + " (UTC) by contact: " + parameters.contact.user_name;
        }
    }
    if(changeCommand == "END"){
        sendDataToParent( {item: "SSASRecord", type: "TEST", ssas_vessel_uuid: scheduledTest});
        //handleSASSRecordDetails('TEST', scheduledTest.schedule_uuid, scheduledTest, userData);
    }
    if(log_change) SP.createPostSSASEventLogParam([parameters]);
}

export async function ssasScheduledTestPostRecord(currentAlert, result, currentContact, event){
    const result_notes = getSSASRecordNotes(event);
    const res_scheduled = await SP.getSSASScheduledTests([],[currentAlert.vessel_info.ssas_vessel_uuid], await SP.scheduledTestStatuses.get(2)[0], false, false, false);

    let currentTest: SP.SSASScheduledTests = res_scheduled[0];
    currentTest.completed_date = G.setUTCDate();
    currentTest.completed_by.user_uuid = currentContact.contact_uuid;
    currentTest.current_status = result;

    currentAlert.end_date = G.setUTCDate();
    const res = await SP.updateScheduledTestDetails(currentTest);
    if(res.is_successful){
        const res_delete_alarm = await SP.DeleteTestAlarm(currentAlert);
        if(res_delete_alarm.is_successful){
            const parameters: SP.eventLogParameters = { alert: currentAlert, vessel:  currentAlert.vessel_info,
                scheduledTests: currentTest, contact: currentContact, log_type_number: 11,  log_mode_number: 2,
                result_notes: result_notes, completed_uuid: currentContact.contact_uuid };
            await SP.createPostSSASEventLogParam([parameters]);
            const parametersVesselLog = parameters;

            if(currentTest.vessel_info === null || currentTest.vessel_info === undefined) currentTest.vessel_info = currentAlert.vessel_info;
            currentTest.vessel_info.current_status = await SP.alertStatusElement.get(2)?.[0];
            const updateVessel = {"vessel_list" : [currentTest.vessel_info]};
            const res_vessel = await SP.upsertSSASVessel(updateVessel);

            parametersVesselLog.log_type_number = 19;
            parametersVesselLog.result_notes = ""+currentTest.vessel_info.vessel_name+" vessel Status Change "+
                currentAlert.vessel_info.current_status+ ", Test mode deactivated at "+ new Date().toISOString() +
                " (UTC), test completed by contact: " + currentContact.user_name;

            if(res_vessel.is_successful) {
                await SP.createPostSSASEventLogParam([parametersVesselLog]);
                const res_affil = await SP.getAffiliationData([],[],[currentTest.vessel_info.ssas_vessel_uuid]);
                await muteSSASAlarm(res_affil, currentContact, currentTest.vessel_info, false, false,
                    "RESET test mute settings for all contacts receiving alerts for vessel "+ currentTest.vessel_info.vessel_name);
            }
        }
    }
    sendDataToParent({ item: "SSASRecord_close", value: currentAlert.test_alert_uuid, type: "TEST" });
}

export async function ssasAlertPostRecord(currentAlert, currentContact, event){
    const recordNotes = getSSASRecordNotes(event);
    const result_notes = "Alert "+ currentAlert.alert_uuid +" deleted for vessel "+
        currentAlert.vessel_info.ssas_vessel_uuid+" at "+ new Date().toISOString() +
        " (UTC), Alert deleted by contact: " + currentContact.user_name;
    const parameters: SP.eventLogParameters = { alert: null, vessel:  currentAlert.vessel_info, scheduledTests: currentAlert,
        contact: currentContact, log_type_number: 8,  log_mode_number: 1, result_notes: result_notes,
        completed_uuid: currentContact.contact_uuid }

    currentAlert.end_date = G.setUTCDate();
    currentAlert.vessel_info.current_status = await SP.alertStatusElement.get(2)?.[0];

    const res_delete_alarm = await SP.DeleteLiveAlarm(currentAlert);

    await SP.createPostSSASEventLogParam([parameters]);
    if(res_delete_alarm .is_successful) {
        const parametersVesselLog = parameters;
        const updateVessel = {"vessel_list" : [currentAlert.vessel_info]};
        const res = await SP.upsertSSASVessel(updateVessel);

        parametersVesselLog.log_type_number = 19;
        parametersVesselLog.result_notes = ""+currentAlert.vessel_info.vessel_name+" vessel Status Change "+
            currentAlert.vessel_info.current_status+ ", Alert mode deactivated at "+ new Date().toISOString() +
            " (UTC), Alert cancelled by contact: " + currentContact.user_name;

        if(res.is_successful) {
            await SP.createPostSSASEventLogParam([parametersVesselLog]);
            const res_affil = await SP.getAffiliationData([],[],[currentAlert.vessel_info.ssas_vessel_uuid]);
            await muteSSASAlarm(res_affil, currentContact, currentAlert.vessel_info, true, false,
                "RESET live mute settings for all contacts receiving alerts for vessel "+ currentAlert.vessel_info.vessel_name);
        }
    }
    sendDataToParent({ item: "SSASRecord_close", value: currentAlert.alert_uuid, type: "LIVE" });
}

export function getSSASRecordNotes(event){
    //let currentElement = event.currentTarget.parentElement;
    let currentElement = event.target.parentElement;
    let i = 0;
    while (currentElement && i < 5) {
        if (currentElement.classList.contains('popup-container')) {
           break; // Stop when the element with the desired class is found
        }
        currentElement = currentElement.parentElement; // Move to the next parent
        i++;
    }
    if (currentElement && currentElement.classList.contains('popup-container')) {
      // Find the textarea within the parent container
      const textarea = currentElement.querySelector('textarea');
      if (textarea) {
        const textareaValue = textarea.value;
        return textareaValue;
      }
    }
    return null;
};

export async function muteSSASAlarm(alerts, userData, vesselData, isLive, new_state, log_notes=""){
    let affiliation_uuids = [];
    let contact_uuids = [];
    alerts.forEach(item =>{
       affiliation_uuids.push(item.link_ssas_vessels_contacts_uuid);
       contact_uuids.push(item.contact_uuid);
    });
    const res = await SP.updateAlarmMute(affiliation_uuids, new_state, isLive);
    if(res.is_successful){
        if(log_notes === "") {
            log_notes = "Change vessel: "+vesselData.vessel_name+" "+ isLive ? "live" : "test" +
            " mute settings for contact "+userData.user_name+" from "+new_state ? "false to true" : "true to false"+"";
        }
        const parameters: SP.eventLogParameters = { alert: null, vessel: vesselData, scheduledTests: null,
            contact: userData, log_type_number: isLive ? 7 : 20,  log_mode_number: isLive ? 1 : 2,
            result_notes: log_notes, completed_uuid: userData.contact_uuid };
        await SP.createPostSSASEventLogParam([parameters]);
    }
    return res;
}