import React, { useEffect, useState, useRef, useMemo, useCallback, useReducer, isValidElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { exportToCSV, exportToExcel, centerTableHeaderfilters } from '../Javascript/Other/General.js';
import { matchSorter } from 'match-sorter';
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel,
  getFilteredRowModel, ColumnFiltersState, flexRender, ColumnDef, Row, RowData } from '@tanstack/react-table';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type TextFilterFn<T> = (rows: Row<T>[], columnId: keyof T, filterValue: string) => Row<T>[];
type Association = [string, string];
type DropdownValue = [string, Association[]];

interface CustomTableProps<T> {
  breadcrumbs: string;
  columns: ColumnDef<T, any>[];
  data: T[] | null;
  onRowClick?: (row: T) => void;
  rows_displayed: number;
  dropdown_placeholder: string;
  expand_element: ((row_id: string, more_info:T | null) => void) | null;
  export_callback: ((data: T[], column: ColumnDef<T, any>[], type: string, breadcrumbs: string) => void) | null;
  //editable_columns: [string, React.ReactNode[] | null][] | null;
  //update_element: ((more_info:T | null, old_value: string, new_value: string, column_id: string) => void) | null;
}
//, editable_columns, update_element
const CustomTable = <T extends object>({ breadcrumbs, columns, data, onRowClick, rows_displayed, dropdown_placeholder, expand_element, export_callback}: CustomTableProps<T>) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [tableName, setTableName] = useState<string>("");
  const memoizedFilters = useMemo(() => filters, [filters]);
  const debouncedFilters = useDebounce(memoizedFilters, 300);
  const [memoizedData, setMemoizedData] = useState<T[]>([]);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: rows_displayed,});
  const memoizedColumns = useMemo(() => columns, [columns]);
  const dropdownValue: DropdownValue[] = [];
  const rerender = useReducer(() => ({}), {})[1];

  const table = useReactTable({
      data: memoizedData,
      columns: memoizedColumns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),  // No arguments here
      state: {
        columnFilters: debouncedFilters,
        pagination,
      },
      onColumnFiltersChange: setFilters,
      onPaginationChange: setPagination,
      debugTable: false,
  });

  useEffect(() => {
      if(data != memoizedData){
        if(data != null){
            setMemoizedData(data);
        }
        else{
            setMemoizedData([]);
        }
        rerender();
      }
      if(tableName == ""){
        let bread = breadcrumbs.split("|");
        setTableName(bread[(bread.length - 1)]);
      }
  }, [data]);
  useEffect(() => {
    let name = breadcrumbs.split('|');
    setTableName(name[name.length - 1]);
  },[]);

  useEffect(() => {
    setPagination({ pageSize: rows_displayed, pageIndex: 0 }); // Reset to the first page when data changes
  }, [memoizedData, rows_displayed]);


  const handleRowClick = useCallback((row: T) => {
      if (onRowClick) onRowClick(row);
    }, [onRowClick]);

  const handleFiltering = useCallback(
    (value: string, columnId: string) => {

      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.filter((filter) => filter.id !== columnId);
        if (value !== "") {
            updatedFilters.push({ id: columnId, value });
        }
        return updatedFilters;
      });
  },[dropdownValue, setFilters]);

  const getUniqueValues = (columnId: string) => {
    const uniqueValues = new Set<any>();
    const column = table.getAllColumns().find(col => col.id === columnId);

    if (column) {
      const columnDef = column.columnDef as { accessorKey?: string; accessorFn?: (original: any) => any };

      if (columnDef) {
        if (columnDef.accessorKey && data != null && Array.isArray(data)) {
          data.forEach(item => {
            const value = item[columnDef.accessorKey as keyof typeof item];
            if (value !== undefined && value !== null) { uniqueValues.add(value); }
          });
        }
        if (columnDef.accessorFn && data != null && Array.isArray(data)) {
          data.forEach(item => {
            const value = columnDef.accessorFn!(item);
            if (value !== undefined && value !== null) { uniqueValues.add(value); }
          });
        }
      }
    }

    return Array.from(uniqueValues);
  };


  const renderTablePagination = useMemo(() => {
    if(data != null && rows_displayed < data.length){
        return (<>
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}> <span className="arrow-left"><span style={{fontSize : '10px', marginTop : '-8px', marginLeft : '-2px'}}>|</span></span> </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}> <span className="arrow-left"></span> </button>
            <p>{table.getState().pagination.pageIndex + 1}</p>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}> <span className="arrow-right"></span> </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}> <span className="arrow-right"><span style={{fontSize : '10px', marginTop : '-8px', marginLeft : '-2px'}}>|</span></span></button>
        </>);
    }
    else{
        return null;
    }
  }, [data, rows_displayed, table, pagination]);
  const renderedTableHeader = useMemo(() => {
    const headerGroups = table.getHeaderGroups();
    return (
      <thead>
        {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
            const { column } = header;
            const columnId = column.id;
            const columnDef = column.columnDef;
            const filterFn = columnDef.filterFn;
            let filterValue = filters.find((filter) => filter.id === columnId)?.value as string || '';
            const rand_number = Math.floor(Math.random() * 9000) + 1000;
            return (
              <th className={header.id} key={header.id+rand_number}>
                <p className="table_header_text" onClick={header.column.getToggleSortingHandler()}>{flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: <span className='sorting_arrow arrow-up'></span>,
                  desc: <span className='sorting_arrow arrow-down'></span>,
                }[header.column.getIsSorted() as string] ?? null}</p>
                {header.column.getCanFilter() && (
                    filterFn === 'equals' ? (
                      <select value={filterValue} onChange={(e) => handleFiltering(e.target.value, columnId)}
                          placeholder={`Filter ${columnDef.header}`} >
                          <option value="">{dropdown_placeholder}</option>
                          {getUniqueValues(columnId).map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                    ) : (
                      <input type="text" value={String(filters.find((filter) => filter.id === columnId)?.value || '')}
                        onChange={(e) => handleFiltering(e.target.value, columnId)} placeholder={`Filter ${columnDef.header}`} />
                    )
                  )}
              </th>
            )})}
          </tr>
        ))}
      </thead>
    );
  }, [table, filters, handleFiltering]);

return (
    <div className='custom-table_component'>
    <div className='custom-table_cont'>
      <table key={tableName} className={`custom-table ${tableName}`}>
        {renderedTableHeader}
        <tbody>
          {table.getRowModel().rows.length === 0 ? (
                  <tr>
                      <td colSpan={columns.length}>No data available</td>
                  </tr>
              ) : (
              table.getRowModel().rows.map((row, index) => {
                 const shouldExpand = expand_element && row.original && 'expand' in row.original && row.original.expand === true;
                 let expandedContent = shouldExpand ? expand_element("", row.original) : null;

                 if(expandedContent && typeof expandedContent === 'object' && typeof (expandedContent as Promise<JSX.Element>).then === 'function') {
                    expandedContent = null;
                 }
                 return (
              <>
                <tr key={tableName +"-"+ index} onClick={() => handleRowClick && handleRowClick(row.original)} className="custom-table-row">
                  {row.getVisibleCells().map(cell => (
                    <td className={cell.column.id} key={tableName +"-"+ cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {expandedContent}
               </>
              )})
          )}
        </tbody>
      </table>
    </div>
      { export_callback != null ? (
          <div className="export_cont">
            <Button variant="light" className="export_csv_button" onClick={() => export_callback(data ?? [], columns, "CSV", breadcrumbs)}>
                <FontAwesomeIcon icon="file-csv" />
            </Button>
            <Button variant="light" className="export_excel_button" onClick={() => export_callback(data ?? [], columns, "EXCEL", breadcrumbs)}>
                <FontAwesomeIcon icon="file-excel" />
            </Button>
          </div>
      ) : null }
      <div className="pagination">
          <div className="change_page_cont">
            {renderTablePagination}
          </div>
      </div>
    </div>
  );
};
//{flexRender(cell.column.columnDef.cell, cell.getContext())}
export default CustomTable;