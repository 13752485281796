import { session_user_uuid, session_parent_uuid, setSessionUserUuid, setSessionParentUuid } from '../session.js';
import { all_user_settings, getUserSettings } from '../Other/General.js';

export const host_url = new URL(window.location.href);
export const component = host_url.searchParams.get('component');
export const parameters = host_url.searchParams.get('parameters');
let trackedVesselUuid = "";

export function sendDataToParent (data) {
    console.log("data: ", data);
    window.parent.postMessage(data, '*');
};

window.addEventListener('message', function (event) {
    handle_iframe_messages(event.data, event);
}, false);

async function handle_iframe_messages(data, event){
    if (data.item === "parsing_user_uuid") {
        await got_user_uuid(data.value, data.value2);
    }
    if (data.item === "parsing_tracked_vessel_uuid") {
        trackedVesselUuid = data.value;
    }
}

export async function got_user_uuid(id, parent_id = ''){
    if(id != ''){
        if(session_user_uuid === ""){
            setSessionUserUuid(id);
        }
        if(session_parent_uuid === "" && parent_id !== ''){
            setSessionParentUuid(parent_id);
        }
    }
    if (!all_user_settings.some(innerArray => typeof innerArray[0] === 'string' && innerArray[0].includes("MapAllOptions"))) {

        var temp = await getUserSettings(id, "MapAllOptions", false);
        if (!all_user_settings.some(innerArray => typeof innerArray[0] === 'string' && innerArray[0].includes("MapAllOptions"))){
            all_user_settings.push(["MapAllOptions", temp]);
        }
    }
}

export function got_tracked_vessel_uuid(){
    return trackedVesselUuid
};