import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-pro/css/all.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import library from './Javascript/Other/FontAwesome_Setup.js';
//import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import MainPage from './Pages/Main_Page';
import Login_Page from './Pages/Login_Page';
import IframeHandler from './Iframe_Handler';
import './CSS/App.css';

interface Props {
  activeTab:string,
  onTabChange:any
}

const App = () => {
  const [currentActiveTab, setActiveTab] = useState<string>('');
  const url = new URL(window.location.href);

  useEffect(()=>{
    const isIframe = url.href.includes('iframe');

    setActiveTab(isIframe ? 'iframe' : 'login');
  },[]);
  return (
    <>
      <Content activeTab={currentActiveTab} onTabChange = {setActiveTab}/>
    </>
  );
  /*
  FA EXAMPLE: <FontAwesomeIcon icon={['fass', 'arrows-rotate']} />
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/" element={<Content activeTab={currentActiveTab} onTabChange = {setActiveTab}/>}/>
                <Route path="/iframe" element={<IframeHandler />}/>
            </Routes>
        </Router>
    </div>
  );*/
};
/* All content comes through this one function, the content is changed with a useState hook */
const Content: React.FC<Props> = ({activeTab, onTabChange}) =>{
  switch(activeTab){
      case 'login':
        return (
          <div>
            <Login_Page onTabChange = {onTabChange} />
          </div>
        );
      case 'main':
        return (<MainPage />);
      case 'iframe':
        return (<IframeHandler />);
      default:
        return (<MainPage />);
  };
};

export default App;